export const routes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/pages/index'),
  },
  {
    path: '/gongju',
    name: 'gongju',
    component: () => import('@/pages/gongju'),
  },
  {
    path: '/kefu',
    name: 'kefu',
    component: () => import('@/pages/kefu'),
  },
];