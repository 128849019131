import Vue from 'vue'
import App from './App.vue'
import VueRoute from 'vue-router'
import { routes } from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(VueRoute)
Vue.use(ElementUI)

const router = new VueRoute({
  routes,
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
